import { pick } from 'lodash-es'
import { v4 as clientUuid } from 'uuid'
import type { MapFiltersType } from '~/atoms'
import { RATE_TYPES, STATUSES, TYPES, fmtNumberAbbr } from '~/utils'
import { DataLayerEntry, DataLayerEntryField } from './dataLayer'
import {
  ListingTransaction,
  META_FIELDS,
  type Listing,
  type MetaFieldConfig,
} from './listing'
import type { ModelUser, TokenUser, User } from './user'

export enum ModelStatus {
  active = 'active',
  inactive = 'inactive',
}

export type ModelFind =
  | {
      [key: string]: any
      'organization._id': string | any
    }
  | {
      [key: string]: any
      'organization.slug': string | any
    }

export type ExternalLink = {
  name: string
  url: string
  type?: string
}

export type Image = {
  _id: string
  url: string
  thumbUrl: string
}

export function getDefaultValues<T = Record<string, any>>(
  user?: User | ModelUser | TokenUser | null,
  defaultValues?: { [key: string]: any }
) {
  const _id = clientUuid()
  return {
    _id,
    tid: _id.split('-')[0],
    created: new Date().toISOString(),
    modified: new Date().toISOString(),
    user: user ? getModelUser(user) : undefined,
    ...defaultValues,
  } as T
}

export function getModelUser(user: any): ModelUser {
  const modelUser = pick(user, [
    '_id',
    'tid',
    'slug',
    'organization',
    'firstName',
    'lastName',
    'email',
    'phone',
    'group',
    'role',
    'company',
  ])
  if (modelUser.company) {
    modelUser.company = pick(modelUser.company, ['_id', 'tid', 'slug', 'name'])
  }
  if (!modelUser.role) {
    delete modelUser.role
  }
  return modelUser
}

export function camelCaseToWords(s: string) {
  const result = s.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const PHONE_REGEX = new RegExp(
  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
)
export const EMAIL_REGEX = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
export const WEB_REGEX = new RegExp(
  /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
)

export function regexPhone(val: string | undefined) {
  if (val && !val.match(PHONE_REGEX)) {
    return false
  }
  return true
}

export function regexWeb(val: string | undefined) {
  if (val && !val.match(WEB_REGEX)) {
    return false
  }
  return true
}

export function regexEmail(val: string | undefined) {
  if (val && !val.match(EMAIL_REGEX)) {
    return false
  }
  return true
}

export const getListingTypeLabel = (listing: Listing) => {
  const labels: { [key: string]: string } = {
    sale: 'For Sale',
    lease: 'For Lease',
    saleLease: 'For Sale/Lease',
  }
  if (listing.status === 'leased') {
    return 'Fully Leased'
  } else if (listing.status === 'sold') {
    return 'Sold'
  }
  return labels[listing.type]
}

export function getDom(date: string | Date) {
  const hours = (new Date().getTime() - new Date(date).getTime()) / 1000 / 3600
  const days = Math.round(hours / 24)
  return days
}

export function getSaleMetaValues(listing: Listing) {
  if (listing.type === 'sale' || listing.type === 'saleLease') {
    const values = []
    // const keys = ['price', 'buildingSf', 'acres']
    if (listing.meta.price) {
      values.push({
        label: 'Price',
        value: '$',
      })
    }
  }
  return []
}

export function getLeaseMetaValues(
  listing: Listing,
  abbr = false,
  valuesOnly = false
): { key: string; label: string; value: string }[] {
  if (
    (listing.type === 'lease' || listing.type === 'saleLease') &&
    (listing.leaseSpaces || []).length > 0 &&
    listing.filters
  ) {
    const filters = listing.filters!
    // const values: { [key: string]: string } = {}
    const values: { key: string; label: string; value: string }[] = []

    if (filters.rateLabel && filters.rateLabelAbbr) {
      values.push({
        key: 'leaseRate',
        label: listing.status === 'leased' ? 'Listed Rate' : 'Lease Rate',
        value: abbr ? filters.rateLabelAbbr : filters.rateLabel,
      })
    } else {
      const minRate =
        listing.leaseSpaces!.length === 1
          ? listing.leaseSpaces![0].minRate
          : listing.status === 'leased'
            ? filters.minRateHistoric
            : filters.minRate
      const maxRate =
        listing.leaseSpaces!.length === 1
          ? listing.leaseSpaces![0].maxRate
          : listing.status === 'leased'
            ? filters.maxRateHistoric
            : filters.maxRate
      const rateType =
        listing.leaseSpaces!.length === 1
          ? RATE_TYPES[listing.leaseSpaces![0].rateType!]
          : 'SF/year'

      if (!minRate && !maxRate) {
        values.push({
          key: 'leaseRate',
          label: listing.status === 'leased' ? 'Listed Rate' : 'Lease Rate',
          value: 'Negotiable',
        })
        // values['Lease Rate'] = `$${minRate} - $${maxRate} ${rateType}`
      } else if (minRate && maxRate && minRate !== maxRate) {
        values.push({
          key: 'leaseRate',
          label: listing.status === 'leased' ? 'Listed Rate' : 'Lease Rate',
          value: valuesOnly
            ? `${minRate} - ${maxRate}`
            : abbr
              ? `$${fmtNumberAbbr(minRate)} - $${fmtNumberAbbr(
                  maxRate
                )} ${rateType}`
              : `$${minRate.toLocaleString()} - $${maxRate.toLocaleString()} ${rateType}`,
        })
      } else if (minRate) {
        values.push({
          key: 'leaseRate',
          label: listing.status === 'leased' ? 'Listed Rate' : 'Lease Rate',
          value: valuesOnly
            ? minRate.toString()
            : abbr
              ? `$${fmtNumberAbbr(minRate)} ${rateType}`
              : `$${minRate.toLocaleString()} ${rateType}`,
        })
      }
    }

    const minSf =
      listing.status === 'leased' ? filters.minSfHistoric : filters.minSf
    const maxSf =
      listing.status === 'leased' ? filters.maxSfHistoric : filters.maxSf
    if (minSf && maxSf && minSf !== maxSf) {
      values.push({
        key: 'availableSf',
        label: listing.status === 'leased' ? 'Listed SF' : 'Available SF',
        value: valuesOnly
          ? `${minSf} - ${maxSf}`
          : abbr
            ? `${fmtNumberAbbr(minSf)} - ${fmtNumberAbbr(maxSf)} SF`
            : `${minSf.toLocaleString()} - ${maxSf.toLocaleString()} SF`,
      })
    } else if (minSf) {
      values.push({
        key: 'availableSf',
        label: listing.status === 'leased' ? 'Listed SF' : 'Available SF',
        value: valuesOnly
          ? minSf.toString()
          : abbr
            ? `${fmtNumberAbbr(minSf)} SF`
            : `${minSf.toLocaleString()} SF`,
      })
    }
    return values
  }
  return []
}

export function getMetaValue(
  value: any,
  metaConfig: MetaFieldConfig,
  meta: {
    [key: string]: any
  },
  valuesOnly = false
) {
  if (valuesOnly) {
    return typeof metaConfig.format === 'function'
      ? metaConfig.format(value, meta, valuesOnly)
      : value
  }
  if (metaConfig.format === 'currency') {
    value = currencyFormatter.format(value).replace('.00', '')
  } else if (typeof metaConfig.format === 'function') {
    value = metaConfig.format(value, meta, valuesOnly)
  } else if (value === true) {
    return 'Yes'
  } else if (
    metaConfig.format === 'number' ||
    metaConfig.suffix ||
    metaConfig.prefix
  ) {
    // Assuming anything with prefix/suffix will be a number, may have to change
    value = value.toLocaleString()
  }
  if (metaConfig.prefix) {
    value = `${metaConfig.prefix} ${value}`
  }
  if (metaConfig.suffix) {
    value =
      metaConfig.suffix === '%' ? `${value}%` : `${value} ${metaConfig.suffix}`
  }
  return value
}

export function getMetaLabelValues(
  meta: {
    [key: string]: any
  },
  valuesOnly = false
): { key: string; label: string; value: string }[] {
  // return Object.keys(meta)
  //   .filter(
  //     (key) =>
  //       META_FIELDS[key] && META_FIELDS[key]?.render !== false && !!meta[key]
  //   )
  return Object.keys(META_FIELDS)
    .filter(
      (key) =>
        META_FIELDS[key].render !== false &&
        (!!meta[key] || META_FIELDS[key].generated)
    )
    .map((key) => {
      const metaConfig = META_FIELDS[key]
      // if (typeof metaConfig === 'undefined') {
      //   return {
      //     key,
      //     label: `${key}`,
      //     value: 'TODO',
      //   }
      // }
      const value = getMetaValue(meta[key] || '', metaConfig, meta, valuesOnly)
      return {
        key,
        label: metaConfig.label ? metaConfig.label : camelCaseToWords(key),
        value,
      }
    })
    .filter(({ value }) => value)
}

export function getReportMetaLabelValues(listing: Listing) {
  const leaseMeta = getLeaseMetaValues(listing)
  const availableSpaces =
    (listing.leaseSpaces || []).length > 0
      ? [
          {
            key: 'availableSpaces',
            label: 'Available Spaces',
            value: listing
              .leaseSpaces!.filter((space) => space.available)
              .length.toString(),
          },
        ]
      : []
  // const dom = intervalToDuration({
  //   start: parseISO(listing.created),
  //   end: new Date(),
  // })
  const metaLabelValues = [
    ...(leaseMeta.length > 0 ? leaseMeta : []),
    ...availableSpaces,
    ...getMetaLabelValues(listing.meta),
    {
      key: 'county',
      label: 'County',
      value: listing.property.county,
    },
    {
      key: 'created',
      label: 'Created',
      value: new Date(listing.created).toISOString().split('T')[0],
    },
    {
      key: 'modified',
      label: 'Updated',
      value: new Date(listing.modified).toISOString().split('T')[0],
    },
  ]
  if (listing.offMarketDate) {
    metaLabelValues.unshift({
      key: 'offMarketDate',
      label: 'Transacted',
      value: new Date(listing.offMarketDate).toISOString().split('T')[0],
    })
  }
  return [
    ...metaLabelValues,
    {
      key: 'tid',
      label: 'Listing ID',
      value: listing.tid,
    },
  ]
}

function renderMinMaxFilter(
  min?: number,
  max?: number,
  prefix?: string,
  suffix?: string
) {
  if (min && max) {
    return `${prefix || ''}${fmtNumberAbbr(min)} - ${fmtNumberAbbr(max)}${
      suffix ? ` ${suffix}` : ''
    }`
  } else if (min) {
    return (
      `> ${prefix || ''} ${fmtNumberAbbr(min)}` + (suffix ? ` ${suffix}` : '')
    )
  }
  return (
    `< ${prefix || ''} ${fmtNumberAbbr(max)}` + (suffix ? ` ${suffix}` : '')
  )
}

export function getMapFilterLabels(filters: MapFiltersType) {
  const labels: string[] = []
  if (!filters) {
    return []
  }

  if (filters.search) {
    labels.push(`"${filters.search}"`)
  }

  if (filters.types)
    labels.push(
      filters.types
        .filter((t) => t != 'saleLease')
        .map((t) => TYPES[t])
        .join(', ')
    )
  if (filters.statuses)
    labels.push(filters.statuses.map((s) => STATUSES[s]).join(', '))
  if (filters.propertyTypes) labels.push(filters.propertyTypes.join(', '))
  if (filters.propertySubtypes) labels.push(filters.propertySubtypes.join(', '))
  if (filters.submarkets) labels.push(filters.submarkets.join(', '))
  if (filters.zoning) labels.push(filters.zoning.join(', '))
  if (filters.postalCodes)
    labels.push(
      filters.postalCodes.length < 4
        ? filters.postalCodes.join(', ')
        : `${filters.postalCodes.length} Postal Codes`
    )
  if (filters.cities)
    labels.push(
      filters.cities.length < 4
        ? filters.cities.join(', ')
        : `${filters.cities.length} Cities`
    )
  if (filters.counties)
    labels.push(
      filters.counties.length < 4
        ? filters.counties.join(', ')
        : `${filters.counties.length} Counties`
    )
  if (filters.companies)
    labels.push(
      `${filters.companies.length} ${filters.companies.length > 1 ? 'Companies' : 'Company'}`
    )
  if (filters.brokers)
    labels.push(
      `${filters.brokers.length} ${filters.brokers.length > 1 ? 'Brokers' : 'Broker'}`
    )
  if (filters.created)
    labels.push(`Created > ${filters.created.toString().split('T')[0]}`)
  if (filters.transacted)
    labels.push(`Transacted > ${filters.transacted.split('T')[0]}`)
  if (filters.sf && filters.sf.filter((v) => v).length > 0) {
    labels.push(renderMinMaxFilter(filters.sf[0], filters.sf[1], '', 'SF'))
  }
  if (filters.acres && filters.acres.filter((v) => v).length > 0) {
    labels.push(
      renderMinMaxFilter(filters.acres[0], filters.acres[1], '', 'ac')
    )
  }
  if (filters.prices && filters.prices.filter((v) => v).length > 0) {
    labels.push(renderMinMaxFilter(filters.prices[0], filters.prices[1], '$'))
  }
  if (filters.rates && filters.rates.filter((v) => v).length > 0) {
    labels.push(
      renderMinMaxFilter(filters.rates[0], filters.rates[1], '$', 'SF/year')!
    )
  }
  // sort
  return labels
}

export function getDataLayerEntryLabelValues(
  entry: DataLayerEntry,
  entryFields: DataLayerEntryField[]
) {
  return Object.keys(entry.meta).map((key) => {
    const field = entryFields.find((f) => f.name === key)
    return field
      ? {
          key,
          label: field.label,
          value: entry.meta[key],
          field,
        }
      : {
          key,
          label: key,
          value: entry.meta[key],
        }
  })
}

export function getTransactionTableData(
  transactions: ListingTransaction[],
  type: 'sale' | 'lease',
  allDAta = false
) {
  const rows: { [key: string]: any } = {}
  const headers: { key: string; label: string }[] = []
  if (type === 'lease') {
    transactions.forEach((transaction) => {
      // Add fields
      rows[transaction._id] = {
        name: transaction.lease?.space.name || 'No Name',
        date: transaction.date.split('T')[0],
        confidential: transaction.confidential ? 'Yes' : 'No',
        propertyType: transaction.propertyType || '',
        rate: [
          transaction.lease?.rate
            ? `$${transaction.lease?.rate.toLocaleString()}`
            : '',
          transaction.lease?.rateType
            ? RATE_TYPES[transaction.lease?.rateType]
            : '',
        ]
          .filter((v) => v)
          .join(' '),
        totalLeaseValue: transaction.lease?.totalLeaseValue
          ? `$${transaction.lease?.totalLeaseValue.toLocaleString()}`
          : '',
        structure: transaction.lease?.structure,
        footage: transaction.lease?.footage
          ? `${transaction.lease?.footage.toLocaleString()} SF`
          : '',
        acres: transaction.lease?.acres
          ? `${transaction.lease?.acres.toLocaleString()} ac`
          : '',
        termInMonths: transaction.lease?.termInMonths
          ? `${transaction.lease?.termInMonths} mo.`
          : '',
        startDate: transaction.lease?.startDate
          ? transaction.lease?.startDate.split('T')[0]
          : '',
      }
    })
    const leaseHeaders = [
      { key: 'name', label: 'Name' },
      { key: 'date', label: 'Date' },
      { key: 'confidential', label: 'Confidential' },
      { key: 'propertyType', label: 'Property Type' },
      { key: 'rate', label: 'Rate', confidential: true },
      { key: 'endRate', label: 'End Rate', confidential: true },
      { key: 'totalLeaseValue', label: 'Total Value', confidential: true },
      { key: 'structure', label: 'Lease Type', confidential: true },
      { key: 'footage', label: 'Footage' },
      { key: 'acres', label: 'Acres' },
      { key: 'termInMonths', label: 'Term', confidential: true },
      { key: 'startDate', label: 'Start Date', confidential: true },
      { key: 'expirationDate', label: 'Expiration Date', confidential: true },
    ]
    leaseHeaders.forEach((header) => {
      if (
        Object.values(rows).some((row) => row[header.key]) &&
        (header.confidential !== true || allDAta)
      ) {
        headers.push(header)
      }
    })
  } else {
    // sale
    transactions.forEach((transaction) => {
      // const buildingSf = transaction.sale?.buildingSf || listing.meta.buildingSf
      // const acres = transaction.sale?.acres || listing.meta.acres

      rows[transaction._id] = {
        date: transaction.date.split('T')[0],
        confidential: transaction.confidential ? 'Yes' : 'No',
        propertyType: transaction.propertyType || '',
        price: transaction.sale?.price
          ? `$${transaction.sale?.price.toLocaleString()}`
          : '',
        buildingSf: transaction.sale?.buildingSf
          ? `${transaction.sale?.buildingSf.toLocaleString()} SF`
          : '',
        acres: transaction.sale?.acres
          ? `${transaction.sale?.acres.toLocaleString()} ac`
          : '',
        terms: transaction.sale?.terms,
        // buyerName: transaction.sale?.buyerName,
        // sellerName: transaction.sale?.sellerName,
      }
    })
    const saleHeaders = [
      { key: 'date', label: 'Date' },
      { key: 'confidential', label: 'Confidential' },
      { key: 'propertyType', label: 'Property Type' },
      { key: 'price', label: 'Price', confidential: true },
      { key: 'buildingSf', label: 'Building SF' },
      { key: 'acres', label: 'Acres' },
      // { key: 'terms', label: 'Terms', confidential: true },
      // { key: 'buyerName', label: 'Buyer Name', confidential: true },
      // { key: 'sellerName', label: 'Seller Name', confidential: true },
    ]
    saleHeaders.forEach((header) => {
      if (
        Object.values(rows).some((row) => row[header.key]) &&
        (header.confidential !== true || allDAta)
      ) {
        headers.push(header)
      }
    })
  }

  return { headers, rows }
}

export function getHeightValue(value?: number) {
  const feet = value ? Math.floor(value) : undefined
  const inches = value
    ? Math.round((value - Math.floor(value)) * 12)
    : undefined

  return [feet && `${feet}'`, inches && `${inches}"`].filter((v) => v).join(' ')
}
